<ng-container
  *ngIf="(errorService.hasError$ | async) === false; else showError"
>
  <header-bar></header-bar>
  <feedback-control></feedback-control>
  <div class="wrapper">
    <sidebar>
      <div class="app">
        <router-outlet></router-outlet>
      </div>
    </sidebar>
  </div>
</ng-container>

<ng-template #showError>
  <error>
    <ng-container>
      <h1 class="title" [translate]="'error.title'"></h1>

      <p class="message" [translate]="'error.description'"></p>

      <p class="message">
        <strong
          [innerText]="
            'error.code' | translate : { errorCode: errorService.errorCode }
          "
        ></strong>
      </p>

      <p class="message" [translate]="'error.apology'"></p>
    </ng-container>

    <ng-container actions>
      <a
        href="https://help.etrusted.com/"
        class="support-link"
        target="_blank"
        [translate]="'error.button.support'"
      ></a>
    </ng-container>
  </error>
</ng-template>
