import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeHeliosIconPrefix'
})
export class RemoveHeliosPrefixPipe implements PipeTransform {
  public transform<T>(value: string, ...args: any[]): string {
    return value.replace(/^helios-icon helios-icon-(.*)$/g, '$1');
  }
}
