import {
  CultureInfo,
  CultureInfoService,
  TOKENS as COMMON_TOKENS,
  Guid,
} from '@trustedshops/tswp-core-common';
import {
  FeedbackBody,
  FeedbackCustomer,
  FeedbackMetadata,
  FeedbackRouteMetadata,
  FeedbackStarter,
  FeedbackTemplate,
  FeedbackTransaction,
  KeycloakTokenPayload,
} from '../models/feedback.interface';
import { Subscription } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { Inject, Injectable, OnDestroy, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import {
  PluginService,
  TOKENS as PLUGIN_TOKENS,
  PluginRegistration,
} from '@trustedshops/tswp-core-plugins';
import { ENVIRONMENT, Environment } from '../models/environment.type';
import {
  IdentityService,
  TOKENS as AUTH_TOKENS,
  Identity,
} from '@trustedshops/tswp-core-authorization';
import { KeycloakProfileData } from '@trustedshops/tswp-core-authorization-keycloak';
import { toPromise } from '@trustedshops/tswp-core-common-eventing-rxjs';

@Injectable()
export class FeedbackDataService implements OnInit, OnDestroy {
  //#region Private Fields
  private _routeMetadata: FeedbackRouteMetadata;
  private _routeChangeSubscription: Subscription;
  //#endregion

  //#region Ctor
  public constructor(
    private readonly _router: Router,

    @Inject(DOCUMENT)
    private readonly _document: Document,

    @Inject(PLUGIN_TOKENS.PluginService)
    private readonly _pluginService: PluginService,

    @Inject(COMMON_TOKENS.CultureInfoService)
    private readonly _cultureInfoService: CultureInfoService,

    @Inject(ENVIRONMENT) private readonly _environment: Environment,

    @Inject(AUTH_TOKENS.IdentityService)
    private readonly _identityService: IdentityService
  ) {}

  //#endregion

  //#region Public Methods
  public ngOnInit(): void {
    const routerEventObservable = this._router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .pipe(map((event) => event as NavigationEnd))
      .pipe(map((event) => event.urlAfterRedirects))
      .pipe(map((url) => url.replace(/([^?]*).*/g, '$1')))
      .pipe(distinctUntilChanged());

    this._routeChangeSubscription = routerEventObservable.subscribe(
      async () => {
        const name = this.getPluginNameOfActiveRoute();
        const registration = this.findPluginRegistrationByName(name);
        const version =
          this.determinePluginVersionFromPluginRegistration(registration);

        this._routeMetadata = {
          page: this._document.title,
          pluginName: name,
          pluginVersion: version,
        };
      }
    );
  }

  public ngOnDestroy(): void {
    if (this._routeChangeSubscription) {
      this._routeChangeSubscription.unsubscribe();
      this._routeChangeSubscription = null;
    }
  }

  public async getFeedbackBody(
    starter: FeedbackStarter
  ): Promise<FeedbackBody> {
    const cultureInfo = await this.getActiveCultureInfo();

    const [questionnaireTemplate, customer, transaction, metadata]: [
      FeedbackTemplate,
      FeedbackCustomer,
      FeedbackTransaction,
      FeedbackMetadata
    ] = await Promise.all([
      this.getFeedbackTemplate(cultureInfo),
      this.getCustomerInformation(),
      this.getTransactionData(),
      this.getRouteMetadata(starter),
    ]);

    return {
      languageTag: cultureInfo.ietfLanguageTag,
      questionnaireLinkRequest: {
        type: 'ts-control-center-usage',
        questionnaireTemplate,
        customer,
        transaction,
        metadata,
      },
    };
  }
  //#endregion

  //#region Private Methods
  private async getRouteMetadata(
    starter: FeedbackStarter
  ): Promise<FeedbackMetadata> {
    const tokenPayload = await this.getTokenPayload();

    return {
      ...this._routeMetadata,
      starter,
      'customer-reference': tokenPayload['https://etrusted.com'].accountRef,
      'customer-reference-type': 'ETRUSTED_ACCOUNT_ID',
    };
  }

  private getPluginNameOfActiveRoute(): string {
    return this._router.routerState.root.firstChild?.snapshot.children[0]?.data
      ?.pluginName;
  }

  private findPluginRegistrationByName(name: string): PluginRegistration {
    return this._pluginService.registeredPlugins.find(
      (plugin) => plugin.name === name
    );
  }

  private determinePluginVersionFromPluginRegistration(
    pluginRegistration: PluginRegistration
  ): string {
    const basePath = pluginRegistration.initialRequest.basePath;
    const name = pluginRegistration.initialRequest.name;
    return basePath.replace(`${name}/`, '');
  }

  private async getCustomerInformation(): Promise<FeedbackCustomer> {
    const tokenPayload = await this.getTokenPayload();

    const email = tokenPayload.email;
    const firstName = tokenPayload.given_name;
    const lastName = tokenPayload.family_name;

    return {
      email,
      ...(firstName && { firstName }),
      ...(lastName && { lastName }),
    };
  }

  private async getTokenPayload(): Promise<KeycloakTokenPayload> {
    const identity = await toPromise<Identity<KeycloakProfileData>>(
      this._identityService.identity
    );

    return await toPromise<KeycloakTokenPayload>(
      identity.profile.keycloak.token.payload
    );
  }

  private getTransactionData(): FeedbackTransaction {
    const guid = Guid.newGuid();
    const randomNumber = Math.floor(Math.random() * 1000000);
    return {
      reference: `${guid}ORDER-123-${randomNumber}`,
      date: new Date().toISOString(),
    };
  }

  private async getFeedbackTemplate(
    cultureInfo: CultureInfo
  ): Promise<FeedbackTemplate> {
    return {
      id: `${this._environment.feedback.templateBase}${cultureInfo.ietfLanguageTag}`,
    };
  }

  private async getActiveCultureInfo(): Promise<CultureInfo> {
    return new Promise((resolve) => {
      let subscription = this._cultureInfoService.currentCulture.subscribe(
        (cultureInfo) => {
          resolve(cultureInfo);
          subscription.unsubscribe();
          subscription = null;
        }
      );
    });
  }
  //#endregion
}
