import { Inject, Injectable } from '@angular/core';
import { NavigationRegions } from '@trustedshops/tswp-carrier-b2b-contracts';
import { RxJsBridge } from '@trustedshops/tswp-core-common-eventing-rxjs';
import {
  OrganizationalContainerSelectionService,
  OrganizationalContainerProvider,
  TOKENS as TOKENS_MASTERDATA
} from '@trustedshops/tswp-core-masterdata';

import {
  OrganizationalContainerSelectorInfo,
  OrganizationalContainerSelectorConfiguration,
  NavigationItem,
  TOKENS,
  NavigationRegionService
} from '@trustedshops/tswp-core-ui';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { mergeDeep } from '../util/merge-object';

export type ActiveNavigationItemsStream = Observable<{
  primary: NavigationItem[];
  secondary: NavigationItem[];
  tertiary: NavigationItem[];
}>;

@Injectable()
export class NavigationBarService {
  //#region Properties
  private _activeItems: ActiveNavigationItemsStream = null;
  public get activeItems(): ActiveNavigationItemsStream {
    if (this._activeItems) {
      return this._activeItems;
    }

    const selectorRelevantNavigationRegions = [
      NavigationRegions.PrimaryMenu,
      NavigationRegions.SecondaryMenu,
      NavigationRegions.TertiaryMenu,
    ];

    const activeItemStreams = selectorRelevantNavigationRegions
      .map(region => this._navigationRegionService.getActiveItems(region))
      .map(regionItems => regionItems.convertWith(RxJsBridge(BehaviorSubject)));

    return this._activeItems = combineLatest(activeItemStreams)
      .pipe(map(([primary, secondary, tertiary]) => [primary || [], secondary || [], tertiary || [] ]))
      .pipe(map(([primary, secondary, tertiary]) => ({ primary, secondary, tertiary})));
  }

  private _organizationalContainerSelector: Observable<OrganizationalContainerSelectorInfo> = null;
  public get organizationalContainerSelector(): Observable<OrganizationalContainerSelectorInfo> {
    if (this._organizationalContainerSelector || !this._activeItems) {
      return this._organizationalContainerSelector;
    }

    return this._organizationalContainerSelector =
      this._activeItems
        .pipe(map(({primary, secondary, tertiary}) => {
          primary = primary || [];
          secondary = secondary || [];
          const configs = [...primary, ...secondary, ...tertiary]
            .filter(item => item)
            .map<OrganizationalContainerSelectorConfiguration>(navigationItem => navigationItem.configuration)
            .filter(configuration => configuration)
            .map(configuration => configuration.organizationalContainerSelector);

          if (!configs.length) {
            return null;
          }

          const defaultConfig: OrganizationalContainerSelectorInfo = {
            renderer: this._organizationalContainerSelectionService.defaultContainerRenderer,
            source: this._organizationalContainerProvider.defaultContainerSource,
            permissions: [
              ['*']
            ]
          };

          const fullyQualifiedConfig = configs
            .map(config => {
              if (typeof config === 'boolean') {
                return { ...defaultConfig };
              }

              return config;
            })
            .reduce<Partial<OrganizationalContainerSelectorInfo>>(
              (previous, current) => mergeDeep(previous, current, true),
              { ...defaultConfig }) as OrganizationalContainerSelectorInfo;

          return fullyQualifiedConfig;
        }));
  }
  //#endregion

  //#region Ctor
  public constructor(
    @Inject(TOKENS.NavigationRegionService)
    private readonly _navigationRegionService: NavigationRegionService,

    @Inject(TOKENS_MASTERDATA.OrganizationalContainerSelectionService)
    private readonly _organizationalContainerSelectionService: OrganizationalContainerSelectionService,

    @Inject(TOKENS_MASTERDATA.OrganizationalContainerProvider)
    private readonly _organizationalContainerProvider: OrganizationalContainerProvider
  ) { }
  //#endregion
}
