<div class="feedback-overlay">
  <div class="feedback-overlay__dismiss">
    <hls-icon (click)="handleDismiss()" color="#98abb4" icon="action-dismiss"></hls-icon>
  </div>

  <div>
    <div>
      <h4 [translate]="'carrier.feedback.popup.headline'"></h4>
      <p [translate]="'carrier.feedback.popup.body'"></p>
    </div>

    <hls-button (click)="handleProvideFeedback()" button-style="primary"><span [translate]="'carrier.feedback.give-feedback'"></span></hls-button>
  </div>
</div>
