import { ExportToken } from '@trustedshops/tswp-core-common';
import { OrganizationalContainerSources } from '@trustedshops/tswp-carrier-b2b-contracts';
import { OrganizationalContainer, OrganizationalContainerSource } from '@trustedshops/tswp-core-masterdata';
import { Inject, Injectable } from '@angular/core';
import {
  Configuration,
  ChannelsApi,
  FetchParams,
  HTTPHeaders,
  RequestContext,
  ChannelDto
} from '@trustedshops/account-service-client';
import { Environment, ENVIRONMENT } from '../models/environment.type';
import { Identity, IdentityService, TOKENS as AUTH_TOKENS } from '@trustedshops/tswp-core-authorization';
import { KeycloakProfileData } from '@trustedshops/tswp-core-authorization-keycloak';
import { Subject } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { RxJsSubjectBridge } from '@trustedshops/tswp-core-common-eventing-rxjs';

@Injectable()
@ExportToken(OrganizationalContainerSources.DefaultOrganizationalContainerSource)
export class DefaultOrganizationalContainerSource implements OrganizationalContainerSource<any> {
  //#region Private Fields
  private _channels: ChannelDto[];
  //#endregion

  //#region Properties
  public get type(): string {
    return OrganizationalContainerSources.DefaultOrganizationalContainerSource;
  }
  //#endregion

  //#region Ctor
  public constructor(
    @Inject(ENVIRONMENT) private readonly _environment: Environment,
    @Inject(AUTH_TOKENS.IdentityService) private readonly _identityService: IdentityService) {

  }
  //#endregion


  //#region Public Methods
  public async getContainers(): Promise<OrganizationalContainer<any>[]> {
    this._channels = this._channels || await this.fetchChannelsFromApi();

    return this._channels.map(channel => ({
      type: OrganizationalContainerSources.DefaultOrganizationalContainerSource,
      id: channel.id,
      displayName: channel.name,
      info: channel,
      resourceType: 'channel'
    }));
  }

  private async fetchChannelsFromApi(): Promise<ChannelDto[]> {
    const { accounts: basePath }: { accounts: string; } = this._environment.apis;
    const accountsApi = this.getApiClient(basePath);
    return accountsApi.getChannels();
  }

  //#endregion

  //#region Private Methods
  private getApiClient(basePath: string): ChannelsApi {
    const config = new Configuration({ basePath });

    let api = new ChannelsApi(config);
    api = api.withPreMiddleware(async (context) => {
      const { url, init }: RequestContext = context;
      const headers = init.headers as HTTPHeaders;

      headers.Authorization = `Bearer ${await this.getToken()}`;

      return { url, init } as FetchParams;
    });

    return api;
  }

  private async getIdentity(): Promise<Identity<KeycloakProfileData>> {
    return await this._identityService.identity
      .convertWith<Subject<Identity<KeycloakProfileData>>>(RxJsSubjectBridge)
      .pipe(
        filter(x => !!x),
        take(1))
      .toPromise();
  }

  private async getToken(): Promise<string> {
    const identity = await this.getIdentity();

    return await identity.profile.keycloak.token.encoded
      .convertWith<Subject<string>>(RxJsSubjectBridge)
      .pipe(
        filter(x => !!x),
        take(1))
      .toPromise();

  }
  //#endregion
}
