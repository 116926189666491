import { TOKENS, PluginService } from '@trustedshops/tswp-core-plugins';

import { Injectable, APP_INITIALIZER, Inject } from '@angular/core';
import {
  TOKENS as TOKENS_COMMON,
  BootstrapperBase,
  LocalStorageService,
  RootTranslationService,
  TranslationFile,
} from '@trustedshops/tswp-core-common';

@Injectable()
export class PluginsBootstrapper implements BootstrapperBase {
  //#region PRivate fields
  private readonly _pluginService: PluginService;
  //#endregion

  //#region Ctor
  public constructor(
    @Inject(TOKENS.PluginService) pluginService: PluginService,
    @Inject(TOKENS_COMMON.LocalStorageService) private readonly _localStorageService: LocalStorageService,
    @Inject(TOKENS_COMMON.TranslationService) private readonly _translationService: RootTranslationService) {

    this._pluginService = pluginService;
  }
  //#endregion

  //#region Public Methods
  public async initialize(): Promise<void> {
    this._pluginService.pluginContextBus.subscribe(pluginContext => {
      // TODO: Replace by factory to reduce memory usage by services not used in the plugins
      pluginContext.serviceProvider.registerTokenizedValue(
        TOKENS_COMMON.LocalStorageService,
        this._localStorageService.createScoped(pluginContext.registration.name));

      const childTranslationService = this._translationService.createChild(pluginContext.registration.name);

      const {
        pluginStorageUrl,
        basePath,
      }: { pluginStorageUrl: string; basePath: string } =
        pluginContext.registration.initialRequest;

      childTranslationService.overrideDefaultConfiguration({
        translationKeyResolver: (translations: TranslationFile, key: string) => translations[key],
        localeFileNameResolver: (locale: string) => `${pluginStorageUrl}/${basePath}/assets/i18n/${locale}.json`
      });

      pluginContext.serviceProvider.registerTokenizedValue(
        TOKENS_COMMON.TranslationService,
        childTranslationService
      );
    });
  }
  //#endregion
}

export const PluginsInitializer = [
  PluginsBootstrapper,
  {
    provide: APP_INITIALIZER,
    multi: true,
    useFactory: (initializer: PluginsBootstrapper) => {
      return () => initializer.initialize();
    },
    deps: [PluginsBootstrapper]
  }
];
