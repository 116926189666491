
export abstract class UserCentricsConsent {
  public constructor(
    readonly _document: Document,
  ) { }

  protected async userConsentObtained(service: string): Promise<boolean> {
    const window: any = this._document.defaultView.window;

    while (!this.userCentricsLoaded()) {
      await new Promise(resolve => setTimeout(resolve, 50));
    }

    const services = window.UC_UI.getServicesBaseInfo();
    const foundService = services.find((x: { name: string; }) => x.name === service);
    return foundService.consent.status;
  }

  private userCentricsLoaded(): boolean {
    const window: any = this._document.defaultView.window;
    if (!window.UC_UI) {
      return false;
    }

    return true;
  }
}
