import { Injectable } from '@angular/core';
import {
  PluginRegistrationInfo,
  PluginResolverService,
} from '@trustedshops/tswp-carrier-core';
import { ActivePluginsService } from './active-plugins.service';

@Injectable()
export class PluginResolverServiceImpl implements PluginResolverService {
  constructor(private _activePluginsService: ActivePluginsService) {}

  //#region Public Methods
  public async resolve(
    targetUserGroup: string
  ): Promise<PluginRegistrationInfo[]> {
    return await this._activePluginsService
      .getPlatformPlugins(targetUserGroup)
      .toPromise();
  }
  //#endregion
}
