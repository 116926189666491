import { NavigationRegions } from '@trustedshops/tswp-carrier-b2b-contracts';
import { Event } from '@trustedshops/tswp-core-common';
import { NavigationRegionConfiguration } from '@trustedshops/tswp-core-ui';

export function primaryNavigationConfiguration(initiallyCollapsed: Event<boolean>): NavigationRegionConfiguration  {
  return {
    name: NavigationRegions.PrimaryMenu,
    animationDurations: {
      expand: 500, // TODO: Define animation durations at 1 (!!) place
      collapse: 500
    },
    initialState: {
      collapsed: initiallyCollapsed
    }
  };
}
