import { environment as fixedEnvironment } from '../../environments/environment';
import { Environment } from '../models/environment.type';
import { mergeDeep } from '../util/merge-object';

declare let platformConfiguration: Environment;
export function getEnvironment(): Environment {
  return mergeDeep(fixedEnvironment, platformConfiguration || {});
}

export const environment = getEnvironment();
