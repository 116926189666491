import { Injectable, APP_INITIALIZER, Inject } from '@angular/core';
import { BootstrapperBase, CultureInfoService, LocalStorageService, TOKENS as TOKENS_COMMON } from '@trustedshops/tswp-core-common';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class HotJarBootstrapper implements BootstrapperBase {
  //#region Ctor
  public constructor(
    @Inject(DOCUMENT) private readonly _document: Document,
    @Inject(TOKENS_COMMON.CultureInfoService) private readonly _cultureInfoService: CultureInfoService,
    @Inject(TOKENS_COMMON.LocalStorageService) private readonly _localStorageService: LocalStorageService) { }
  //#endregion

  //#region Public Methods
  public async initialize(): Promise<void> {
    this.initializeHotjarLanguageSync();
  }
  //#endregion

  //#region Private Methods
  private async initializeHotjarLanguageSync(): Promise<void> {
    const window: any = this._document.defaultView.window;
    while (!window.hj) {
      await new Promise(resolve => setTimeout(resolve, 50));
    }

    this._cultureInfoService.currentCulture.subscribe(x => {
      if (!x) {
        return;
      }

      if (!window.hj) {
        return false;
      }

      const userAttributes: { [index: string]: string } = { };

      try {
        const value = this._localStorageService.select<boolean>('feedback_design_mode');

        if (value) {
          userAttributes.feedback_design_mode = 'yes';
        }
      } catch { }

      window.hj('identify', null, {
        locale: x.ietfLanguageTag,
        feedback_design_mode: 'no',
        ...userAttributes
      });
    });
  }
  //#endregion
}

export const HotJarInitializer = [
  HotJarBootstrapper,
  {
    provide: APP_INITIALIZER,
    multi: true,
    useFactory: (initializer: HotJarBootstrapper) => {
      return () => initializer.initialize();
    },
    deps: [HotJarBootstrapper]
  }
];
