import {
  Component,
  OnInit,
  ViewEncapsulation,
  Output,
  EventEmitter,
  Inject,
} from '@angular/core';
import { LogService, TOKENS } from '@trustedshops/tswp-core-common';

@Component({
  selector: 'feedback-popup',
  templateUrl: './feedback-popup.component.html',
  styleUrls: ['./feedback-popup.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FeedbackPopupComponent implements OnInit {
  //#region Statics
  private static readonly TYPE = 'FeedbackPopupComponent';
  //#endregion

  //#region Properties
  @Output()
  public readonly onDismiss = new EventEmitter();

  @Output()
  public readonly onShowModal = new EventEmitter();
  //#endregion

  //#region Ctor
  public constructor(
    @Inject(TOKENS.LogService) private readonly _logService: LogService
  ) {}
  //#endregion

  //#region Public Methods
  public async ngOnInit(): Promise<void> {
    this._logService.debug(
      FeedbackPopupComponent.TYPE,
      'Feedback popup initialized'
    );
  }

  public async handleProvideFeedback(): Promise<void> {
    this._logService.debug(
      FeedbackPopupComponent.TYPE,
      'Feedback popup provide feedback clicked'
    );
    this.onShowModal.emit();
  }

  public async handleDismiss(): Promise<void> {
    this._logService.debug(
      FeedbackPopupComponent.TYPE,
      'Feedback popup dismiss clicked'
    );
    this.onDismiss.emit();
  }
  //#endregion
}
