import { BootstrapperBase, LogService, TOKENS } from '@trustedshops/tswp-core-common';
import { FeatureBookingBootstrapper } from './feature-booking.bootstrapper';
import { PermissionsBootstrapper } from './permissions.bootstrapper';
import { APP_INITIALIZER, Inject, Injectable } from '@angular/core';
import { ErrorCode, ErrorService, SystemCriticalError } from '@trustedshops/tswp-carrier-core';

@Injectable()
export class SystemCriticalBootstrapper implements BootstrapperBase {
  private readonly TYPE: string = 'SystemCriticalBootstrapper';

  public constructor(
    @Inject(TOKENS.LogService)
    private readonly _logService: LogService,
    private readonly _errorService: ErrorService,
    private readonly _permissionsBootstrapper: PermissionsBootstrapper,
    private readonly _featureBookingBootstrapper: FeatureBookingBootstrapper,
  ) {
  }

  public async initialize(): Promise<void> {
    const initializers: [BootstrapperBase, ErrorCode][] = [
      [this._permissionsBootstrapper, ErrorCode.PermissionError],
      [this._featureBookingBootstrapper, ErrorCode.FeatureBookingError]
    ];

    for (const [initializer, errorCode] of initializers) {
      try {
        this._logService.trace(this.TYPE, `Initializing ${initializer.constructor.name}`);
        await initializer.initialize();
      } catch (error) {
        const systemError = SystemCriticalError.forService(error, errorCode);
        this._logService.error(this.TYPE, systemError);
        return this._errorService.activateErrorState(systemError);
      }
    }
  }
}

export const SystemCriticalBootstrapperInitializer = [
  PermissionsBootstrapper,
  FeatureBookingBootstrapper,
  SystemCriticalBootstrapper,
  {
    provide: APP_INITIALIZER,
    multi: true,
    useFactory: (initializer: SystemCriticalBootstrapper) => () => initializer.initialize(),
    deps: [SystemCriticalBootstrapper]
  }
];
