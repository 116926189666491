import { CommonModule, DOCUMENT } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, Inject, Injector, NgModule } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { BrowserModule } from '@angular/platform-browser';
import { DefaultOrganizationalContainerRendererComponent } from './default-organizational-container-renderer.component';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [DefaultOrganizationalContainerRendererComponent],
  exports: [DefaultOrganizationalContainerRendererComponent],
  imports: [
    CommonModule,
    BrowserModule
  ]
})
export class DefaultOrganizationalContainerRendererModule {
  //#region Private Fields
  private readonly _injector: Injector;
  private readonly _document: Document;
  //#endregion

  //#region Ctor
  /**
   * Creates a new instance of DefaultOrganizationalContainerRendererModule
   *
   * @param injector The injector to use for instantiating the controls
   * @param document The document object to register the WebComponents at
   */
  public constructor(injector: Injector, @Inject(DOCUMENT) document: Document) {
    this._injector = injector;
    this._document = document;
  }
  //#endregion

  //#region Public Methods
  /**
   * Initializes this module and all associated custom elements
   */
  public ngDoBootstrap(): void {
    const customElement = createCustomElement(DefaultOrganizationalContainerRendererComponent, {
      injector: this._injector
    });

    this._document.defaultView.customElements
      .define(
        DefaultOrganizationalContainerRendererComponent.selector,
        customElement);
  }
  //#endregion
}
