import { Injectable, APP_INITIALIZER, Inject } from '@angular/core';
import { BootstrapperBase, CultureInfoService, LocalStorageService, TOKENS as TOKENS_COMMON } from '@trustedshops/tswp-core-common';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class LocaleSyncBootstrapper implements BootstrapperBase {
  //#region Ctor
  public constructor(
    private readonly _translateService: TranslateService,
    @Inject(TOKENS_COMMON.CultureInfoService) private readonly _cultureInfoService: CultureInfoService) { }
  //#endregion

  //#region Public Methods
  public async initialize(): Promise<void> {
    this.syncLocales();
  }
  //#endregion

  //#region Private Methods
  private async syncLocales(): Promise<void> {
    this._cultureInfoService.currentCulture
      .subscribe(culture => {
        if (!culture) {
          return;
        }

        this._translateService.use(culture.ietfLanguageTag);
      });
  }
  //#endregion
}

export const LocaleSyncInitializer = [
  LocaleSyncBootstrapper,
  {
    provide: APP_INITIALIZER,
    multi: true,
    useFactory: (initializer: LocaleSyncBootstrapper) => {
      return () => initializer.initialize();
    },
    deps: [LocaleSyncBootstrapper]
  }
];
