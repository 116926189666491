import { TranslateLoader } from '@ngx-translate/core';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';

class WebpackTranslationLoader implements TranslateLoader {
  public getTranslation(lang: string): Observable<any> {
    return from(import(`../../assets/i18n/${lang}.json`))
      .pipe(map(res => res.default));
  }
}

export const translateLoaderDeps = [];
export function createTranslateLoader(): WebpackTranslationLoader {
  return new WebpackTranslationLoader();
}
