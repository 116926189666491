import { Injectable, APP_INITIALIZER, Inject, Injector } from '@angular/core';
import { BootstrapperBase } from '@trustedshops/tswp-core-common';
import { DOCUMENT } from '@angular/common';
import { createCustomElement } from '@angular/elements';
import {
  OrganizationalContainerSelectorComponent
} from '../controls/organizational-container-selector/organizational-container-selector.component';

@Injectable()
export class WebComponentsBootstrapper implements BootstrapperBase {
  public constructor(
    @Inject(DOCUMENT) private readonly _document: Document,
    @Inject(Injector) private readonly _injector: Injector) { }

  public async initialize(): Promise<void> {
    const customElement = createCustomElement(OrganizationalContainerSelectorComponent, {
      injector: this._injector
    });

    this._document.defaultView.customElements
      .define(
        OrganizationalContainerSelectorComponent.selector,
        customElement);
  }
}

export const WebComponentsInitializer = [
  WebComponentsBootstrapper,
  {
    provide: APP_INITIALIZER,
    multi: true,
    useFactory: (initializer: BootstrapperBase) => {
      return () => initializer.initialize();
    },
    deps: [WebComponentsBootstrapper]
  }
];
