import { NgModule, APP_INITIALIZER } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserFlowService } from './user-flow.service';

export const initializeUserFlow = (userFlowService: UserFlowService) => () => userFlowService.init();

@NgModule({
  imports: [CommonModule],
  providers: [
    UserFlowService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeUserFlow,
      deps: [UserFlowService],
      multi: true,
    },
  ],
})
export class UserFlowModule {}
