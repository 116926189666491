import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { RxJsSubjectBridge } from '@trustedshops/tswp-core-common-eventing-rxjs';
import { OrganizationalContainer, OrganizationalContainerSelectionService, TOKENS } from '@trustedshops/tswp-core-masterdata';
import { DynamicPluginControl } from '@trustedshops/tswp-core-plugins';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { RxJsSubscriberComponent } from '../../core/rxjs-subscriber.component';

@Component({
  selector: 'web-component-wrapper',
  templateUrl: 'web-component-wrapper.component.html',
  styleUrls: ['web-component-wrapper.component.scss']
})
export class WebComponentWrapperComponent extends RxJsSubscriberComponent implements OnInit, OnDestroy {
  //#region Private Fields
  private readonly _previousElementNames: BehaviorSubject<string> = new BehaviorSubject(null);
  private attributeSubscription: Subscription;

  //#endregion

  //#region Properties
  private _elementName: BehaviorSubject<string> = new BehaviorSubject(null);
  @Input()
  public get elementName(): string {
    return this._elementName.value;
  }
  public set elementName(v: string) {
    this._elementName.next(v);
  }

  private _elementAttributes: BehaviorSubject<{ [name: string]: any }> = new BehaviorSubject({});
  @Input()
  public get elementAttributes(): { [name: string]: any } {
    return this._elementAttributes.value;
  }
  public set elementAttributes(v: { [name: string]: any }) {
    this._elementAttributes.next(v);
  }

  /**
   * Container element inside the component
   */
  @ViewChild('container', { static: true })
  public container: ElementRef;

  /**
   * Gets the currently rendered plugin control
   */
   public get currentElement(): DynamicPluginControl {
    if (!this.container || !this.container.nativeElement || !this.container.nativeElement.children.length) {
      return null;
    }

    return this.container.nativeElement.children[0] as DynamicPluginControl;
  }
  //#endregion

  //#region Ctor
  public constructor(@Inject(DOCUMENT) private readonly _document: Document) {
    super();
  }
  //#endregion

  //#region Public Methods
  public async ngOnInit(): Promise<void> {
    this.rememberSubscription(this._elementName
      .subscribe(elementName => this.renderComponent(elementName)));
  }

  /**
   * Life Cycle Hook run when this component is destroyed
   */
   public async ngOnDestroy(): Promise<void> {
    super.ngOnDestroy();

    if (this.attributeSubscription) {
      this.attributeSubscription.unsubscribe();
      this.attributeSubscription = null;
    }

    if (typeof this.currentElement.beforeDestroyed === 'function') {
      await this.currentElement.beforeDestroyed();
    }
  }
  //#endregion

  //#region Private Methods
  private async renderComponent(elementName: string): Promise<void> {
    const previousElementName = await this._previousElementNames
      .pipe(take(1))
      .toPromise();

    if (elementName && previousElementName === elementName) {
      return;
    }

    const element = this._document.createElement(elementName) as DynamicPluginControl;
    if (typeof element.beforeAppended === 'function') {
      await element.beforeAppended();
    }

    if (this.attributeSubscription) {
      this.attributeSubscription.unsubscribe();
      this.attributeSubscription = null;
    }

    if (this.currentElement && typeof this.currentElement.beforeDestroyed === 'function') {
      await this.currentElement.beforeDestroyed();
    }

    for (const child of this.container.nativeElement.children) {
      this.container.nativeElement.removeChild(child);
    }

    this.container.nativeElement.appendChild(element);
    this.applyAttributes(
      element,
      await this._elementAttributes
        .pipe(take(1))
        .toPromise());

    this.attributeSubscription = this._elementAttributes.subscribe(elementAttributes =>
      this.applyAttributes(element, elementAttributes));

    if (typeof this.currentElement.afterAppended === 'function') {
      await this.currentElement.afterAppended();
    }
  }

  private applyAttributes(element: DynamicPluginControl, attributes: { [name: string]: any }): void {
    if (!element) {
      return;
    }

    for (const attributeName in attributes) {
      element[attributeName] = attributes[attributeName];
    }
  }
  //#endregion
}
