<div class="container">
  <link rel="stylesheet" href="/helios.css" />
  <ng-container *ngIf="isListItem || !allContainersSelected">
    <div class="color-muted"
          *ngIf="container.info.url"
          [innerText]="container.info.url"></div>
    <div class="color-muted"
          *ngIf="container.info.address"
          [innerText]="container.info.address"></div>
  </ng-container>
</div>