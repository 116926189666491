<div class="organizational-container-selector"
     *ngIf="selectedContainers | async as selection$"
     (clickedOutside)="closeContainerList()"
     [ngClass]="{
       'organizational-container-selector--pure': pure,
       'organizational-container-selector--wide': wide
     }">
  <ng-container *ngIf="renderer | async as renderer$">
    <ng-container *ngIf="containers | async as containers$">
      <div
        class="organizational-container-selector__title color-muted"
        [translate]="'carrier.channel-selector.selected-channel'"></div>
      <div class="organizational-container-selector__selection"
           [ngClass]="{ 'organizational-container-selector__selection--opened': listOpened | async }"
           (click)="toggleContainerList()">
        <organizational-container
          [container]="selection$[0]"
          [allContainers]="containers$">
          <web-component-wrapper
            [elementName]="renderer$"
            #selectedContainer
            [elementAttributes]="{
              container: selection$[0],
              containers: containers$,
              selection: selection$,
              isListItem: false
            }"></web-component-wrapper>
        </organizational-container>
        <div class="icon-wrapper">
          <hls-icon icon="arrow-chevron-down"></hls-icon>
        </div>
      </div>
      <div class="organizational-container-selector__list"
           #listContainer
           [ngClass]="{
            'organizational-container-selector__list--opened': listOpened | async,
            'organizational-container-selector__list--visible': listVisible | async
           }">
        <div class="organizational-container-selector__list__headline">
          <h2 [translate]="'carrier.channel-selector.your-channels'"></h2>
          <ng-template #selectAllContent>
            <div class="organizational-container-selector__list__select-all">
              <div>
                <hls-icon icon="editor-list-unordered"></hls-icon>
              </div>
              <div [translate]="'carrier.channel-selector.select-all-channels'"></div>
            </div>
          </ng-template>

          <div *ngIf="!selection$.length">
            <hls-toggle-button-group value="all" *ngIf="containers$.length > 1">
              <hls-toggle-button value="all" (click)="selectContainers([])">
                <ng-container *ngTemplateOutlet="selectAllContent"></ng-container>
              </hls-toggle-button>
            </hls-toggle-button-group>
          </div>

          <div *ngIf="selection$.length">
            <hls-toggle-button-group value="none"  *ngIf="containers$.length > 1">
              <hls-toggle-button value="all" (click)="selectContainers([])">
                <ng-container *ngTemplateOutlet="selectAllContent"></ng-container>
              </hls-toggle-button>
            </hls-toggle-button-group>
          </div>
        </div>
        <div class="organizational-container-selector__list__items" #containerList>
          <organizational-container
            class="organizational-container-selector__list__items__item"
            *ngFor="let container of containers$"
            #containers
            [container]="container"
            [allContainers]="containers$"
            (click)="selectContainers([container])"
            [ngClass]="{
              'organizational-container-selector__list__items__item--selected': isSelected(selection$, container)
            }">
            <web-component-wrapper
              [elementName]="renderer$"
              #containerHosts
              [elementAttributes]="{
                container: container,
                containers: containers$,
                selection: selection$,
                isListItem: true
              }"></web-component-wrapper>
          </organizational-container>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>