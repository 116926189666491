import { NgModule } from '@angular/core';

import { ApiModule as PluginsApiModule, BASE_PATH } from '@trustedshops/tswp-api-plugins-client-ng';
import { Environment, ENVIRONMENT } from '../models/environment.type';

@NgModule({
  imports: [PluginsApiModule],
  providers: [
    {
      provide: BASE_PATH,
      useFactory: (environment: Environment) => environment.apis.plugins,
      deps: [ENVIRONMENT]
    }
  ]
})
export class PluginsApiConfigurationModule { }
