<div class="feedback-modal">
  <div class="feedback-modal__backdrop">
    <div class="feedback-modal__backdrop__container">
      <div class="feedback-modal__backdrop__container__questionnaire" *ngIf="isUrlAssigned && !questionnairePromise && !hasError">
        <div class="feedback-modal__backdrop__container__questionnaire__dismiss">
          <hls-icon (click)="handleClose()" color="#98abb4" icon="action-dismiss"></hls-icon>
        </div>

        <iframe #questionnaireIframe [src]="questionnaireURI" title="Questionnaire"></iframe>
      </div>

      <div class="feedback-modal__backdrop__container--loading" *ngIf="!isReady && !hasError">
        <hls-loading-indicator></hls-loading-indicator>
      </div>
    </div>
  </div>
</div>
