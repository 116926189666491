import { BrowserModule } from '@angular/platform-browser';
import { ApplicationInitStatus, CUSTOM_ELEMENTS_SCHEMA, Inject, Injector, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { PresentationComponent } from './views/presentation/presentation.component';
import { SharedModule } from './configuration/shared.module';
import { HeaderBarComponent } from './controls/header-bar/header-bar.component';
import { SidebarComponent } from './controls/sidebar/sidebar.component';
import { environment } from './configuration/get-environment.util';
import { Environment, ENVIRONMENT } from './models/environment.type';
import { PluginResolverServiceImpl } from './services/plugin-resolver.service';
import { RouterModule } from '@angular/router';
import { TOKENS } from '@trustedshops/tswp-core-authorization';
import { AngularKeycloakHttpInterceptor } from '@trustedshops/tswp-core-authorization-keycloak-implementation';
import { forcedLastInListRoutes } from './configuration/routing.bootstrapper';
import {
  OrganizationalContainerSelectorComponent
} from './controls/organizational-container-selector/organizational-container-selector.component';
import { WebComponentWrapperComponent } from './controls/web-component-wrapper/web-component-wrapper.component';
import { OrganizationalContainerComponent } from './controls/organizational-container/organizational-container.component';
import { ClickedOutsideDirective } from './directive/clicked-outside.directive';
import { LogLevel } from '@trustedshops/tswp-core-common';
import { TertiaryMenuBarComponentInitializer } from './controls/tertiary-menu-bar/tertiary-menu-bar.bootstrapper';
import { TertiaryMenuBarComponent } from './controls/tertiary-menu-bar/tertiary-menu-bar.component';
import { NavigationBarService } from './services/navigation-bar.service';
import { AsObservablePipe } from './pipes/as-observable.pipe';
import { NavigationBadgeComponent } from './controls/navigation-badge/navigation-badge.component';
import { GoogleAnalyticsTrackingInitializer } from './configuration/ga-tracking.bootstrapper';
import { TrackingConfiguration } from './configuration/tracking.configuration';
import { UserCentricsInitializer } from './configuration/user-centrics.bootstrapper';
import { DOCUMENT } from '@angular/common';
import { WebComponentsInitializer } from './configuration/web-components.bootstrapper';
import { ApplicationInitStatusEx } from './diagnostics/application-init-status';
import { HotJarInitializer } from './configuration/hot-jar.bootstrapper';
import { LocaleSyncInitializer } from './configuration/locale-sync.bootstrapper';
import { RemoveHeliosPrefixPipe } from './pipes/remove-helios-prefix.pipe';
import { FeedbackSleeveComponent } from './controls/feedback-sleeve/feedback-sleeve.component';
import { FeedbackControlComponent } from './controls/feedback-control/feedback-control.component';
import { FeedbackModalComponent } from './controls/feedback-modal/feedback-modal.component';
import { FeedbackService } from './services/feedback.service';
import { FeedbackPopupComponent } from './controls/feedback-popup/feedback-popup.component';
import { FeedbackDataService } from './services/feedback-data.service';
import { PageTitleBootstrapperInitializer } from './configuration/page-title.bootstrapper';
import { ReviewsService } from './services/reviews.service';
import { UserService } from './services/user.service';
import { UpgradePageModule, UpgradePlusModule, UpgradePopupModule, HubspotModule } from '@trustedshops/tswp-core-ui-implementation';
import { UpgradeButtonComponent } from './controls/upgrade-button/upgrade-button.component';
import { HubSpotInitializer } from './configuration/hubspot.bootstrapper';
import { UserFlowModule } from './services/user-flow.module';

declare var platformConfiguration: Environment;

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    ClickedOutsideDirective,

    PresentationComponent,
    HeaderBarComponent,
    SidebarComponent,
    TertiaryMenuBarComponent,
    OrganizationalContainerSelectorComponent,
    OrganizationalContainerComponent,
    WebComponentWrapperComponent,
    NavigationBadgeComponent,
    FeedbackControlComponent,
    FeedbackPopupComponent,
    FeedbackSleeveComponent,
    FeedbackModalComponent,

    AsObservablePipe,
    RemoveHeliosPrefixPipe,
    UpgradeButtonComponent
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot([
      ...forcedLastInListRoutes], {
      enableTracing: (platformConfiguration?.diagnostics?.logging?.logLevels || []).includes(LogLevel.Trace)
    }),
    SharedModule,
    HubspotModule.forRoot({
      baseUrl: environment.freeAccount.hubspot.upgradePage.baseUrl,
      authToken: environment.freeAccount.hubspot.upgradePage.authToken,
      portalId: environment.freeAccount.hubspot.upgradePage.portalId,
      fullContactFormId: environment.freeAccount.hubspot.upgradePage.fullContactFormId,
      shortContactFormId: environment.freeAccount.hubspot.upgradePage.shortContactFormId,
      ctaContactFormId: environment.freeAccount.hubspot.upgradePage.ctaContactFormId,
    }),
    UserFlowModule
  ],
  exports: [
    BrowserModule,
    RouterModule,
    SharedModule,
    UpgradePageModule,
    UpgradePopupModule,
    UpgradePlusModule,
  ],
  providers: [
    PageTitleBootstrapperInitializer,
    { provide: ApplicationInitStatus, useClass: ApplicationInitStatusEx },
    ...TrackingConfiguration,
    UserCentricsInitializer,
    NavigationBarService,
    GoogleAnalyticsTrackingInitializer,
    TertiaryMenuBarComponentInitializer,
    HotJarInitializer,
    HubSpotInitializer,
    WebComponentsInitializer,
    LocaleSyncInitializer,
    PluginResolverServiceImpl,
    { provide: TOKENS.NgHttpInterceptor, useClass: AngularKeycloakHttpInterceptor },
    { provide: ENVIRONMENT, useFactory: () => environment },
    FeedbackService,
    FeedbackDataService,
    ReviewsService,
    UserService,
  ]
})
export class AppModule {
  private readonly _injector: Injector;
  private readonly _document: Document;

  /**
   * Creates a new instance of DefaultOrganizationalContainerRendererModule
   *
   * @param injector The injector to use for instantiating the controls
   * @param document The document object to register the WebComponents at
   */
  public constructor(injector: Injector, @Inject(DOCUMENT) document: Document) {
    this._injector = injector;
    this._document = document;

    if (localStorage.getItem('d3bug')) {
      const appInitStatus = injector.get(ApplicationInitStatus);

      this.checkInits(appInitStatus as ApplicationInitStatusEx);
      (window as any).tswp = (window as any).tswp || {};
      (window as any).tswp.injector = injector;
    }
  }

  private async checkInits(appInits: ApplicationInitStatusEx): Promise<void> {
    while (!appInits.done) {
      await new Promise(resolve => setTimeout(resolve, 1000));

      if (!appInits.asyncInitPromises) {
        continue;
      }
      const mappings = appInits.asyncInitPromises.map((promise, i) => ({
        initFunction: (appInits as any).appInits[i],
        promise: promise as any
      }));

      const openInits = mappings
        .filter(x => !x.promise.__zone_symbol__state)
        .map(x => x.initFunction);

      console.log('Still awaiting inits of', openInits);
    }
  }
  /**
   * Initializes this module and all associated custom elements
   */
  public ngDoBootstrap(): void { }
}
