<ng-template #content>
  <ng-content></ng-content>
</ng-template>
<div class="organizational-container">
  <div *ngIf="container"
       class="organizational-container__title">
    <strong [innerText]="container.displayName"></strong>
  </div>
  <div *ngIf="!container"
       class="organizational-container__title organizational-container__title--all-selected">
    <div>
      <strong [translate]="'carrier.channel-selector.all-channels'"></strong>
    </div>
    <div>
      <span [innerText]="'carrier.channel-selector.channels' | translate:{ totalAmount: allContainers.length }"></span>
    </div>
    <div>
      <ng-template [ngTemplateOutlet]="content"></ng-template>
    </div>
  </div>
  <div
    *ngIf="container"
    class="organizational-container__info">
    <ng-template [ngTemplateOutlet]="content"></ng-template>
  </div>
</div>