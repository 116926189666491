<div class="sidebar">
  <ng-container *ngIf="activeItems && (activeItems | async) as activeItems$">
    <div class="sidebar-container">
      <ng-container *ngIf="sidebarPrimaryItems | async as sidebarPrimaryItems$">
        <nav
          class="sidebar-container-primary_menu"
          [ngClass]="
            (isNavigationCollapsed | async)
              ? 'sidebar-container-primary_menu--collapsed'
              : 'sidebar-container-primary_menu--expanded'
          "
        >
          <ul>
            <ng-container
              *ngFor="
                let navigationItem of sidebarPrimaryItems$ | sort : 'order'
              "
            >
              <li
                class="sidebar-container-primary_menu-item"
                *ngIf="navigationItem.isVisible.value"
              >
                <user-interaction
                  [interaction]="navigationItem.userInteraction"
                  [renderingOptions]="{
                    linkActiveOptions: { exact: false },
                    forcedActive: activeItems$.primary.includes(navigationItem)
                  }"
                  class="sidebar-container-primary_menu-item-user_interaction"
                >
                  <div
                    class="sidebar-container-primary_menu-item-content"
                    [matTooltip]="navigationItem.label"
                    matTooltipPosition="right"
                  >
                    <div class="sidebar-container-primary_menu-item-icon">
                      <hls-icon
                        [icon]="navigationItem.icon | removeHeliosIconPrefix"
                      ></hls-icon>
                      <navigation-badge
                        *ngIf="!!navigationItem.badge"
                        class="navigation-badge--floating navigation-badge--small"
                        [badge]="navigationItem.badge"
                      ></navigation-badge>
                    </div>

                    <span
                      class="sidebar-container-primary_menu-item-label"
                      [innerText]="navigationItem.label"
                    ></span>
                  </div>
                </user-interaction>
              </li>
            </ng-container>
            <li
              class="sidebar-container-primary_menu-item sidebar-container-primary_menu-item--align-bottom"
            >
              <a (click)="togglePrimaryMenu()">
                <span class="sidebar-container-primary_menu-item-icon">
                  <hls-icon
                    [icon]="
                      (isNavigationCollapsed | async)
                        ? 'arrow-chevron-right'
                        : 'arrow-chevron-left'
                    "
                  ></hls-icon>
                </span>
              </a>
            </li>
          </ul>
        </nav>
      </ng-container>
      <ng-container
        *ngIf="sidebarSecondaryItems | async as sidebarSecondaryItems$"
      >
        <div
          class="sidebar-container-secondary_menu"
          *ngIf="sidebarSecondaryItems$ && sidebarSecondaryItems$.length"
        >
          <organizational-container-selector
            *ngIf="organizationalContainerSelector | async"
            [configuration]="organizationalContainerSelector"
          ></organizational-container-selector>
          <nav>
            <ul>
              <ng-container
                *ngFor="
                  let navigationItem of sidebarSecondaryItems$ | sort : 'order'
                "
              >
                <li
                  class="sidebar-container-secondary_menu-item"
                  *ngIf="navigationItem.isVisible.value"
                >
                  <user-interaction
                    [interaction]="navigationItem.userInteraction"
                    [renderingOptions]="{
                      linkActiveOptions: { exact: true },
                      forcedActive:
                        activeItems$.secondary.includes(navigationItem)
                    }"
                    class="sidebar-container-secondary_menu-item-user_interaction"
                  >
                    <div class="sidebar-container-secondary_menu-item__wrapper">
                      <div>
                        <span [innerText]="navigationItem.label"></span>
                        <tswp-upgrade-plus
                          *ngIf="navigationItem.plus"
                        ></tswp-upgrade-plus>
                      </div>
                      <navigation-badge
                        *ngIf="!!navigationItem.badge"
                        [badge]="navigationItem.badge"
                      ></navigation-badge>
                    </div>
                  </user-interaction>
                </li>
              </ng-container>
            </ul>
          </nav>
          <nav class="sidebar-container-legal_menu">
            <ul>
              <li
                *ngFor="
                  let navigationItem of sidebarLegalItems | sort : 'order'
                "
              >
                <user-interaction
                  [interaction]="navigationItem.userInteraction"
                >
                  <span [innerText]="navigationItem.label"></span>
                </user-interaction>
              </li>
            </ul>
          </nav>
        </div>
      </ng-container>
    </div>
  </ng-container>
  <div class="sidebar-content">
    <ng-content></ng-content>
  </div>
</div>
