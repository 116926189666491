import {
  Component,
  OnInit,
  ViewEncapsulation,
  Output,
  EventEmitter,
  Inject,
  Input,
} from '@angular/core';
import { LogService, TOKENS } from '@trustedshops/tswp-core-common';

@Component({
  selector: 'feedback-sleeve',
  templateUrl: './feedback-sleeve.component.html',
  styleUrls: ['./feedback-sleeve.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FeedbackSleeveComponent implements OnInit {
  //#region Statics
  private static readonly TYPE = 'FeedbackSleeveComponent';
  //#endregion

  //#region Properties
  @Output()
  public readonly onShowModal = new EventEmitter();

  @Input('isActive')
  private _isActive: boolean;
  public get isActive(): boolean {
    return this._isActive;
  }
  public set isActive(v: boolean) {
    this._isActive = v;
  }
  //#endregion

  //#region Ctor
  public constructor(
    @Inject(TOKENS.LogService)
    private readonly _logService: LogService
  ) {}
  //#endregion

  //#region Public Methods
  public async ngOnInit(): Promise<void> {
    this._logService.debug(
      FeedbackSleeveComponent.TYPE,
      'Feedback sleeve initialized'
    );
  }

  public async handleClick(): Promise<void> {
    this._logService.debug(
      FeedbackSleeveComponent.TYPE,
      'Feedback sleeve clicked'
    );
    this.onShowModal.emit();
  }
  //#endregion
}
