import { Inject, Injectable } from '@angular/core';
import { BootstrapperBase } from '@trustedshops/tswp-core-common';
import { FeatureBookingService, TOKENS } from '@trustedshops/tswp-core-masterdata';
import { ENVIRONMENT, Environment } from '../models/environment.type';

@Injectable()
export class FeatureBookingBootstrapper implements BootstrapperBase {
  public constructor(
    @Inject(ENVIRONMENT) private readonly _environment: Environment,
    @Inject(TOKENS.FeatureBookingService) private readonly _featureBookingService: FeatureBookingService,
  ) {
  }
  public async initialize(): Promise<void> {
    await this._featureBookingService.initialize({
      basePath: this._environment.apis.featureBooking
    });
  }
}
