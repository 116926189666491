import { Component, ViewEncapsulation } from '@angular/core';
import { ErrorService } from '@trustedshops/tswp-carrier-core';

@Component({
  selector: 'presentation',
  templateUrl: './presentation.component.html',
  styleUrls: ['./presentation.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PresentationComponent {

  public constructor(
    public readonly errorService: ErrorService,
  ) { }
}
