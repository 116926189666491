import { TOKENS, BreadcrumbsCompositionService, NavigationService } from '@trustedshops/tswp-core-ui';
import { Injectable, APP_INITIALIZER, Inject } from '@angular/core';
import { BootstrapperBase } from '@trustedshops/tswp-core-common';
import { Router } from '@angular/router';
import { NavigationRegions } from '@trustedshops/tswp-carrier-b2b-contracts';
import { DefaultBreadcrumbProvider } from '@trustedshops/tswp-carrier-core';

@Injectable()
export class BreadcrumbsBootstrapper implements BootstrapperBase {
  //#region Ctor
  public constructor(
    @Inject(TOKENS.NavigationService)
    private readonly _navigationService: NavigationService,

    @Inject(TOKENS.BreadcrumbsCompositionService)
    private readonly _breadcrumbsCompositionService: BreadcrumbsCompositionService,

    private readonly _router: Router,
    ) {
  }
  //#endregion

  //#region Public Methods
  public async initialize(): Promise<void> {
    const defaultProvider = new DefaultBreadcrumbProvider(
      this._router,
      this._navigationService,
      NavigationRegions.PrimaryMenu,
      NavigationRegions.SecondaryMenu);

    defaultProvider.initialize();

    this._breadcrumbsCompositionService.configureDefaultBreadcrumbProvider(defaultProvider);
  }
  //#endregion
}

export const BreadcrumbsInitializer = [
  BreadcrumbsBootstrapper,
  {
    provide: APP_INITIALIZER,
    multi: true,
    useFactory: (initializer: BootstrapperBase) => {
      return () => initializer.initialize();
    },
    deps: [BreadcrumbsBootstrapper]
  }
];
