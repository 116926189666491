import { Component, Input, ViewEncapsulation } from '@angular/core';
import { RxJsBridge } from '@trustedshops/tswp-core-common-eventing-rxjs';
import { NavigationItemBadge } from '@trustedshops/tswp-core-ui';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

interface BadgeConfig {
  isVisible: boolean;
  content: string;
  pulsing: boolean;
}

@Component({
  selector: 'navigation-badge',
  templateUrl: './navigation-badge.component.html',
  styleUrls: ['./navigation-badge.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NavigationBadgeComponent {
  //#region Properties
  private _badge: NavigationItemBadge;
  @Input()
  public get badge(): NavigationItemBadge {
    return this._badge;
  }
  public set badge(value: NavigationItemBadge) {
    this._badge = value;

    this._badgeConfig = value
      ? combineLatest([
          value.content?.convertWith(RxJsBridge(BehaviorSubject)) || of(null),
          value.isVisible?.convertWith(RxJsBridge(BehaviorSubject)) || of(true),
          value.pulsing?.convertWith(RxJsBridge(BehaviorSubject)) || of(false),
        ]).pipe(map(([content, isVisible, pulsing]) => ({
          isVisible, content, pulsing
        })))
      : null;
  }

  private _badgeConfig: Observable<BadgeConfig>;
  public get badgeConfig(): Observable<BadgeConfig> {
    return this._badgeConfig;
  }
  //#endregion
}
