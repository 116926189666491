import { Component, Input, ViewEncapsulation } from '@angular/core';
import { OrganizationalContainer } from '@trustedshops/tswp-core-masterdata';

@Component({
  selector: 'organizational-container',
  templateUrl: 'organizational-container.component.html',
  styleUrls: ['organizational-container.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OrganizationalContainerComponent {
  //#region Properties
  private _allContainers: OrganizationalContainer<any>[];
  @Input()
  public get allContainers(): OrganizationalContainer<any>[] {
    return this._allContainers;
  }
  public set allContainers(v: OrganizationalContainer<any>[]) {
    this._allContainers = v;
  }

  private _container: OrganizationalContainer<any>;
  @Input()
  public get container(): OrganizationalContainer<any> {
    return this._container;
  }
  public set container(v: OrganizationalContainer<any>) {
    this._container = v;
  }
  //#endregion
}
