import { Inject, Injectable } from '@angular/core';
import { PluginHubService } from '@trustedshops/tswp-api-plugins-client-ng';
import { PluginRegistrationInfo } from '@trustedshops/tswp-carrier-core';
import {
  FeatureBookingService,
  TOKENS as MASTERDATA_TOKENS,
} from '@trustedshops/tswp-core-masterdata';
import {
  NavigationItem
} from '@trustedshops/tswp-core-ui';
import {
  BehaviorSubject,
  combineLatest,
  from,
  Observable
} from 'rxjs';
import { filter, first, map, tap } from 'rxjs/operators';

  /**
   * @alias ActivePluginsService
   * @description Service to manage active plugins, fetch first active plugin
   * and filter active plugins based on user account verification status.
   */
@Injectable({ providedIn: 'root' })
export class ActivePluginsService {
  // #region Private fields
  private readonly _firstActiveNavigationItemSubject =
    new BehaviorSubject<NavigationItem | null>(null);
  private readonly _targetUserPluginsSubject = new BehaviorSubject<
    PluginRegistrationInfo[]
  >([]);
  // #endregion
  // #region Public fields
  public static readonly HOME_PLUGIN_LABEL = 'home';
  public readonly targetUserPlugins$ = this._targetUserPluginsSubject
    .asObservable()
    .pipe(filter((plugins) => plugins.length > 0));
  public readonly isUnverifiedAccount$ = from(
    this._featureBookingService.isUnverifiedAccount()
  );
  public readonly activePlugins$ = combineLatest([
    this.targetUserPlugins$,
    this.isUnverifiedAccount$,
  ]).pipe(
    map(([plugins, isUnverifiedAccount]) =>
      this.filterActivePlugins(plugins, isUnverifiedAccount)
    )
  );
  public readonly firstActiveNavigationItem$ =
    this._firstActiveNavigationItemSubject.asObservable();
  // #endregion
  
  constructor(
    @Inject(MASTERDATA_TOKENS.FeatureBookingService)
    private _featureBookingService: FeatureBookingService,
    private _pluginHubService: PluginHubService
  ) {}

  /**
   * @alias filterActivePlugins
   * @description Filter active plugins based on user account verification status.
   * @param plugins - all the plugins despite of autostart status and active status
   * @param isUnverifiedAccount - if account is in unverified state, for example for free account
   * @returns filtered active plugins
   */
  public filterActivePlugins(
    plugins: PluginRegistrationInfo[],
    isUnverifiedAccount = false
  ) {
    return plugins.filter(
      (plugin: PluginRegistrationInfo) =>
        (isUnverifiedAccount && plugin.autostartUnverifiedAccount) ||
        (plugin.active && plugin.autostart) ||
        !isUnverifiedAccount
    );
  }

  /**
   * @alias getPlatformPlugins
   * @param targetGroup - example: b2b 
   * @returns list of plugins from plugin hub
   */
  public getPlatformPlugins(
    targetGroup: string
  ): Observable<PluginRegistrationInfo[]> {
    return this._pluginHubService.listPlatformPlugins(targetGroup).pipe(
      first(),
      tap((plugins) => this._targetUserPluginsSubject.next(plugins))
    );
  }

  /**
   * @alias getNavigationForFirstActivePlugin
   * @description provides corresponging navigation item for first active plugin
   * @param navigationItems 
   * @param plugins 
   * @returns navigation item for first active plugin
   */
  public getNavigationForFirstActivePlugin(
    navigationItems: NavigationItem[],
    plugins: PluginRegistrationInfo[]
  ): NavigationItem {
    // identify first active plugin by id
    const commonNavItem = navigationItems.find(
      (navigationItem) =>
        navigationItem &&
        navigationItem.id &&
        navigationItem.id.includes(plugins[0].name ?? '')
    );
    // find fallback home plugin if available
    const homeNaviationFallbackItem = navigationItems.find(
      (navItem) => navItem.label === ActivePluginsService.HOME_PLUGIN_LABEL
    );
    // if no active plugin found and no home plugin as well, fallback to first navigation item
    const navigationItemFallback =
      commonNavItem ?? homeNaviationFallbackItem ?? navigationItems[0];
    // * NOTE: will have value domain-verification plugin for unverified account
    this._firstActiveNavigationItemSubject.next(navigationItemFallback);
    return navigationItemFallback;
  }
}
