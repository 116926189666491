import { Injectable, APP_INITIALIZER, Inject } from '@angular/core';
import { BootstrapperBase, CultureInfoService, TOKENS as TOKENS_COMMON } from '@trustedshops/tswp-core-common';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class UserCentricsBootstrapper implements BootstrapperBase {
  //#region Ctor
  public constructor(
    @Inject(DOCUMENT) private readonly _document: Document,
    @Inject(TOKENS_COMMON.CultureInfoService) private readonly _cultureInfoService: CultureInfoService) { }
  //#endregion

  //#region Public Methods
  public async initialize(): Promise<void> {
    this.initializeUserCentricsLanguageSync();
  }
  //#endregion

  //#region Private Methods
  private async initializeUserCentricsLanguageSync(): Promise<void> {
    while (!this.userCentricsLoaded()) {
      await new Promise(resolve => setTimeout(resolve, 50));
    }

    this._cultureInfoService.currentCulture.subscribe(x => {
      if (!x) {
        return;
      }

      const window: any = this._document.defaultView.window;
      if (!window.UC_UI) {
        return false;
      }

      window.UC_UI.updateLanguage(x.iso2LanguageCode);
    });
  }

  private userCentricsLoaded(): boolean {
    const window: any = this._document.defaultView.window;
    if (!window.UC_UI) {
      return false;
    }

    return true;
  }
  //#endregion
}

export const UserCentricsInitializer = [
  UserCentricsBootstrapper,
  {
    provide: APP_INITIALIZER,
    multi: true,
    useFactory: (initializer: BootstrapperBase) => {
      return () => initializer.initialize();
    },
    deps: [UserCentricsBootstrapper]
  }
];
