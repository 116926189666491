import { TOKENS, PluginRouter } from '@trustedshops/tswp-core-plugins';
import { Injectable, APP_INITIALIZER, Inject } from '@angular/core';
import { BootstrapperBase } from '@trustedshops/tswp-core-common';
import { Router } from '@angular/router';
import { environment } from './get-environment.util';

export const forcedLastInListRoutes = [
  {
    path: '',
    redirectTo: environment.startPage.url,
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: environment.startPage.url,
  }
];

@Injectable()
export class RoutingBootstrapper implements BootstrapperBase {
  //#region PRivate fields
  private readonly _pluginRouter: PluginRouter;
  //#endregion

  //#region Ctor
  public constructor(
    private readonly _router: Router,
    @Inject(TOKENS.PluginRouter) pluginRouter: PluginRouter,
    ) {

    this._pluginRouter = pluginRouter;
  }
  //#endregion

  //#region Public Methods
  public async initialize(): Promise<void> {
    this._pluginRouter.overrideCreateRouteHandler(defaultHandler => {
      return (route, parent) => {
        if (route.path !== 'etrusted' && parent.children !== this._router.config) {
          defaultHandler(route, parent);
          return;
        }

        const notFoundWildCardRouteIndex = parent.children.findIndex(x => x.path === '**');
        if (notFoundWildCardRouteIndex >= 0) {
          parent.children.splice(notFoundWildCardRouteIndex, 1);
        }

        defaultHandler(route, parent);

        parent.children.push(...forcedLastInListRoutes);
      };
    });
  }
  //#endregion
}

export const RoutingInitializer = [
  RoutingBootstrapper,
  {
    provide: APP_INITIALIZER,
    multi: true,
    useFactory: (initializer: RoutingBootstrapper) => {
      return () => initializer.initialize();
    },
    deps: [RoutingBootstrapper]
  }
];
