import { Injectable, APP_INITIALIZER, Inject } from '@angular/core';
import { BootstrapperBase } from '@trustedshops/tswp-core-common';
import { TOKENS as TOKENS_ANALYTICS, TrackerType, TrackingService } from '@trustedshops/tswp-core-analytics';
import { Environment, ENVIRONMENT } from '../models/environment.type';
import { UserCentricsConsent } from '../core/user-centrics-consent';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class GoogleAnalyticsTrackingBootstrapper extends UserCentricsConsent implements BootstrapperBase {
  public constructor(
    @Inject(TOKENS_ANALYTICS.TrackingService) private readonly _trackingService: TrackingService,
    @Inject(ENVIRONMENT) private readonly _environment: Environment,
    @Inject(DOCUMENT) readonly _document: Document,
  ) {
    super(_document);
  }

  public async initialize(): Promise<void> {
    const hasGoogleAnalytics4Consent = await this.hasGoogleAnalytics4Consent();
    this.ensureGoogleAnalyticsTrackerRegistered(hasGoogleAnalytics4Consent);
  }

  private async hasGoogleAnalytics4Consent(): Promise<boolean> {
    return await this.userConsentObtained('Google Analytics 4');
  }

  private ensureGoogleAnalyticsTrackerRegistered(hasGoogleAnalytics4Consent: boolean): void {
    if (this._trackingService.trackers.includes(this._environment.tracking.googleAnalytics)) {
      return;
    }

    if (hasGoogleAnalytics4Consent) {
      this._trackingService
        .registerTracker(TrackerType.GoogleAnalytics, {
          property: this._environment.tracking.googleAnalytics4,
          anonymize_ip: true,
          send_page_view: false,
          gtagScriptUri: 'https://www.googletagmanager.com/gtag/js'
        });
    }
  }

}

export const GoogleAnalyticsTrackingInitializer = [
  GoogleAnalyticsTrackingBootstrapper,
  {
    provide: APP_INITIALIZER,
    multi: true,
    useFactory: (initializer: GoogleAnalyticsTrackingBootstrapper) => {
      return () => initializer.initialize();
    },
    deps: [GoogleAnalyticsTrackingBootstrapper]
  }
];
