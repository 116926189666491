<div>
  <ng-container *ngIf="badgeConfig | async as $badgeConfig">
    <div
      *ngIf="$badgeConfig.isVisible"
      class="badge"
      [ngClass]="{
        'badge--pulsing': $badgeConfig.pulsing || false,
        'badge--with-content': !!$badgeConfig.content
      }">
      <span *ngIf="!!$badgeConfig.content" [innerText]="$badgeConfig.content"></span>
    </div>
  </ng-container>
</div>
