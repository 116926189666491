import { NgModule } from '@angular/core';
import { AppModule } from '../app/app.module';
import { PresentationComponent } from '../app/views/presentation/presentation.component';

@NgModule({
  imports: [
    AppModule,
  ],
  bootstrap: [PresentationComponent]
})
export class BrowserAppModule {

}
