import { TOKENS, PermissionService, IdentityService, Permission } from '@trustedshops/tswp-core-authorization';
import { Injectable, Inject } from '@angular/core';
import { BootstrapperBase } from '@trustedshops/tswp-core-common';
import { B2bPermissionManager } from '../services/b2b-permission-manager';
import { Environment, ENVIRONMENT } from '../models/environment.type';

@Injectable()
export class PermissionsBootstrapper implements BootstrapperBase {
  private readonly _permissionService: PermissionService<Permission>;

  public constructor(
    @Inject(ENVIRONMENT) private readonly _environment: Environment,
    @Inject(TOKENS.IdentityService) private readonly _identityService: IdentityService,
    @Inject(TOKENS.PermissionService) permissionService: PermissionService<Permission>) {

    this._permissionService = permissionService;
  }

  public async initialize(): Promise<void> {
    this._permissionService.registerPermissionManager(
      new B2bPermissionManager(
        this._environment,
        this._identityService));
  }
}
