import {
  Component,
  ElementRef,
  Inject,
  Input,
  NgZone,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewEncapsulation
} from '@angular/core';
import { RxJsBridge } from '@trustedshops/tswp-core-common-eventing-rxjs';
import {
  OrganizationalContainer,
  OrganizationalContainerProvider,
  OrganizationalContainerRenderer,
  OrganizationalContainerSelectionService,
  TOKENS as TOKENS_MASTERDATA
} from '@trustedshops/tswp-core-masterdata';
import {
  OrganizationalContainerSelectionServiceConnector
} from '@trustedshops/tswp-core-masterdata-implementation';
import { PluginService, TOKENS as TOKENS_PLUGINS } from '@trustedshops/tswp-core-plugins';
import { OrganizationalContainerSelectorInfo } from '@trustedshops/tswp-core-ui';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { filter,  switchMap, take, tap } from 'rxjs/operators';
import { RxJsSubscriberComponent } from '../../core/rxjs-subscriber.component';
import { WebComponentWrapperComponent } from '../web-component-wrapper/web-component-wrapper.component';
import { DOCUMENT } from '@angular/common';
import { NavigationBarService } from '../../services/navigation-bar.service';

interface  ContainerElementMapping {
  element: ElementRef<any>;
  containerHost: WebComponentWrapperComponent;
}

@Component({
  selector: 'organizational-container-selector',
  templateUrl: 'organizational-container-selector.component.html',
  styleUrls: ['organizational-container-selector.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class OrganizationalContainerSelectorComponent extends RxJsSubscriberComponent implements OnInit {
  //#region Static Fields
  public static readonly selector: string = 'tswp-carrier-b2b-org-container-selector';
  //#endregion

  //#region Private Fields
  private _configurationSubscription: Subscription;
  //#endregion

  //#region Properties
  @ViewChildren('selectedContainer', { read: WebComponentWrapperComponent })
  public selectedContainerElement: WebComponentWrapperComponent;

  @ViewChildren('containers', { read: ElementRef })
  public containerElements: QueryList<ElementRef>;

  private _containerList: ElementRef;

  private _listVisible: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public get listVisible(): BehaviorSubject<boolean> {
    return this._listVisible;
  }
  public set listVisible(v: BehaviorSubject<boolean>) {
    this._listVisible = v;
  }

  @ViewChild('containerList')
  public set containerList(v: ElementRef) {
    if (!v) {
      return;
    }

    this._containerList = v;

    const containerListWrapper = v?.nativeElement;
    containerListWrapper.addEventListener('scroll', () =>
      this.checkVisibleElements());
    containerListWrapper.addEventListener('wheel', () =>
      this.checkVisibleElements());
  }

  @ViewChild('listContainer')
  public listContainer: ElementRef<HTMLDivElement>;

  @ViewChildren('containerHosts', { read: WebComponentWrapperComponent })
  public containerHosts: QueryList<WebComponentWrapperComponent>;

  private _renderer: Observable<string>;
  public get renderer(): Observable<string> {
    return this._renderer;
  }
  public set renderer(v: Observable<string>) {
    this._renderer = v;
  }

  private _listOpened: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public get listOpened(): BehaviorSubject<boolean> {
    return this._listOpened;
  }
  public set listOpened(v: BehaviorSubject<boolean>) {
    this._listOpened = v;
  }

  private _containers: Observable<OrganizationalContainer<any>[]>;
  public get containers(): Observable<OrganizationalContainer<any>[]> {
    return this._containers;
  }
  public set containers(v: Observable<OrganizationalContainer<any>[]>) {
    this._containers = v;
  }

  private _selectedContainers: Subject<OrganizationalContainer<any>[]>;
  public get selectedContainers(): Subject<OrganizationalContainer<any>[]> {
    return this._selectedContainers;
  }
  public set selectedContainers(v: Subject<OrganizationalContainer<any>[]>) {
    this._selectedContainers = v;
  }

  private _pure: boolean = false;
  /**
   * Gets or set a value indicating wether the selector should be decorated (e.g. with a title and/or borders)
   */
  @Input()
  public get pure(): boolean {
    return this._pure;
  }
  public set pure(v: boolean) {
    this._pure = v;
  }

  private _wide: boolean = false;
  /**
   * Gets or set a value indicating wether the selector should be decorated (e.g. with a title and/or borders)
   */
  @Input()
  public get wide(): boolean {
    return this._wide;
  }
  public set wide(v: boolean) {
    this._wide = v;
  }

  private _configuration: Observable<OrganizationalContainerSelectorInfo>;
  @Input()
  public get configuration(): Observable<OrganizationalContainerSelectorInfo> {
    return this._configuration;
  }
  public set configuration(v: Observable<OrganizationalContainerSelectorInfo>) {
    const configurationHasChanged = this._configuration !== v;

    if (configurationHasChanged) {
      this._configuration = v;
      this.updateConfigurationListener();
    }
  }
  //#endregion

  //#region Ctor
  public constructor(
    @Inject(TOKENS_MASTERDATA.OrganizationalContainerSelectionService)
    private readonly _organizationalContainerSelector: OrganizationalContainerSelectionService,

    @Inject(TOKENS_MASTERDATA.OrganizationalContainerProvider)
    private readonly _organizationalContainerProvider: OrganizationalContainerProvider,

    @Inject(TOKENS_PLUGINS.PluginService)
    private readonly _pluginService: PluginService,

    private readonly _navigationBarService: NavigationBarService,

    @Inject(DOCUMENT)
    private readonly _document: Document,

    private readonly zone: NgZone) {
    super();
  }
  //#endregion

  //#region Public Methods
  public isSelected(selection: OrganizationalContainer<any>[], container: OrganizationalContainer<any>): boolean {
    return selection.map(x => x.id).includes(container.id);
  }

  public async ngOnInit(): Promise<void> {
    this.configuration = this._navigationBarService.organizationalContainerSelector;

    const connector = this._organizationalContainerSelector as any as OrganizationalContainerSelectionServiceConnector;

    connector.onShowSelectorList = () =>
      setTimeout(() =>
        this.toggleContainerList(true));

    this._containers = this._organizationalContainerSelector
      .getAllContainers()
      .convertWith(RxJsBridge(BehaviorSubject))
      .pipe(filter<OrganizationalContainer<any>[]>(x => !!x));

    this._selectedContainers = this._organizationalContainerSelector
      .getSelection()
      .convertWith(RxJsBridge(BehaviorSubject));

    this._selectedContainers.pipe(tap(() => this.zone.run(() => {})));

    this.rememberSubscription(this.listOpened
      .pipe(filter(x => !!x))
      .subscribe(() => setTimeout(() => this.checkVisibleElements())));

    this.rememberSubscription(
      this.listOpened
        .pipe(filter(() => !!this.listContainer?.nativeElement))
        .subscribe(isOpened => isOpened
          ? this.updateMaxHeight()
          : this.listVisible.next(false)));

    this._renderer = this._organizationalContainerSelector
      .currentContainerRenderer
      .convertWith(RxJsBridge(BehaviorSubject))
      .pipe(filter(x => !!x))
      .pipe(switchMap(async info => {
        if (info.plugin) {
          await this._pluginService.loadPluginByName(info.plugin);
        }
        return info.webComponent;
      }));
  }

  private updateMaxHeight(): void {
    const { nativeElement: listContainer }: ElementRef<HTMLDivElement> = this.listContainer;
    const previousDisplayMode = listContainer.style.display;
    listContainer.style.maxHeight = 'none';
    listContainer.style.display = 'block';

    const containerHeight = listContainer.clientHeight;
    const { y: top }: { y: number } = listContainer.getBoundingClientRect();
    const windowHeight = this._document.defaultView.innerHeight;

    if (top + containerHeight > windowHeight) {
      const screenMargin = 16;
      const maxHeight = windowHeight - top - screenMargin * 2;
      const minHeight = 180;

      listContainer.style.maxHeight = `${Math.max(maxHeight, minHeight)}px`;
    }
    listContainer.style.display = previousDisplayMode;
    this.zone.run(() => this.listVisible.next(true));
  }

  public async jumpToContainer(container: OrganizationalContainer<any>): Promise<void> {
    const mappings = this.getContainerElementMappings();
    const mapping = mappings.find(x => {
      const organizationalContainerRenderer = x.containerHost.currentElement as any as OrganizationalContainerRenderer<any>;
      return organizationalContainerRenderer.container?.id === container.id;
    });

    if (!mapping) {
      return;
    }

    const { element }: ContainerElementMapping = mapping;
    await new Promise(resolve => setTimeout(resolve));
    this._containerList.nativeElement.scrollTop = element.nativeElement.offsetTop - element.nativeElement.clientHeight - 100;
  }

  public checkVisibleElements(): void {
    const mappings = this.getContainerElementMappings();

    const firstInView = mappings.findIndex(({ element, containerHost }) =>
      this.checkIfIsInView(this._containerList.nativeElement, element.nativeElement));

    let lastInView = mappings.length - 1;

    for (let i = Math.max(firstInView, 0); i < mappings.length; i++) {
      const containerInView = this.checkIfIsInView(this._containerList.nativeElement, mappings[i].element.nativeElement);

      if (containerInView) {
        continue;
      }

      lastInView = i - 1;
      break;
    }

    const visibleContainers = mappings.slice(firstInView, lastInView);
    for (const { containerHost } of visibleContainers) {
      const organizationalContainerRenderer = containerHost.currentElement as any as OrganizationalContainerRenderer<any>;
      if (organizationalContainerRenderer.onVisibilityChange) {
        organizationalContainerRenderer.onVisibilityChange(true);
      }
    }

    const invisibleContainers = [];
    if (firstInView > 0) {
      invisibleContainers.push(...mappings.slice(0, firstInView - 1));
    }

    if (lastInView < mappings.length - 1) {
      invisibleContainers.push(...mappings.slice(lastInView + 1));
    }

    for (const { containerHost } of invisibleContainers) {
      const organizationalContainerRenderer = containerHost.currentElement as any as OrganizationalContainerRenderer<any>;
      if (organizationalContainerRenderer.onVisibilityChange) {
        organizationalContainerRenderer.onVisibilityChange(false);
      }
    }
  }

  private getContainerElementMappings(): ContainerElementMapping[] {
    const elements = this.containerElements.toArray();
    const containerHosts = this.containerHosts.toArray();
    const dtos = elements.map((element, i) => ({
      element,
      containerHost: containerHosts[i]
    }));
    return dtos;
  }

  private checkIfIsInView(container: HTMLElement, element: HTMLElement, partial: boolean = true): boolean {
    const cTop = container.scrollTop;
    const cBottom = cTop + container.clientHeight;

    const eTop = element.offsetTop;
    const eBottom = eTop + element.clientHeight;

    const isTotal = (eTop >= cTop && eBottom <= cBottom);
    const isPartial = partial && (
      (eTop < cTop && eBottom > cTop) ||
      (eBottom > cBottom && eTop < cBottom)
    );

    return (isTotal || isPartial);
  }

  public closeContainerList(): void {
    this.listOpened.next(false);
  }

  public selectContainers(container: OrganizationalContainer<any>[]): void {
    this._organizationalContainerSelector.selectContainers(container);
    this.closeContainerList();
  }

  public async toggleContainerList(forceOpen: boolean = false): Promise<void> {
    const [isOpened, selectedContainers]: [boolean, OrganizationalContainer<any>[]] = await Promise.all([
      forceOpen
        ? Promise.resolve(false)
        : this.listOpened.pipe(take(1)).toPromise(),
      this.selectedContainers.pipe(take(1)).toPromise()
    ]);

    const shouldOpen = !isOpened;
    if (selectedContainers.length && shouldOpen) {
      this.jumpToContainer(selectedContainers[0]);
    }

    this.zone.run(() => this.listOpened.next(!isOpened));
  }
  //#endregion

  //#region Private Methods
  private updateConfigurationListener(): void {
    if (this._configurationSubscription) {
      this._configurationSubscription.unsubscribe();
      this._configurationSubscription = null;
    }

    this.rememberSubscription(
      this._configurationSubscription = this._configuration
        .subscribe(config => {
          if (config === null) {
            this._organizationalContainerSelector
              .changeContainerRenderer(this._organizationalContainerSelector.defaultContainerRenderer);
            this._organizationalContainerSelector
              .setRequiredPermissions(['*']);
            this._organizationalContainerProvider
              .activateSource(this._organizationalContainerProvider.defaultContainerSource);
            return;
          }

          this._organizationalContainerSelector.changeContainerRenderer(config.renderer);
          this._organizationalContainerSelector.setRequiredPermissions(...config.permissions);

          this._organizationalContainerProvider.activateSource(config.source);
        }));
  }
  //#endregion
}
