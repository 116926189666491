import { Inject, Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { from, Observable, Subject, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import {
  TOKENS as COMMON_TOKENS,
  LogService,
} from '@trustedshops/tswp-core-common';
import { environment } from '../../environments/environment';
import {
  Identity,
  IdentityService,
  TOKENS as AUTH_TOKENS,
} from '@trustedshops/tswp-core-authorization';
import { KeycloakProfileData } from '@trustedshops/tswp-core-authorization-keycloak';
import { RxJsSubjectBridge } from '@trustedshops/tswp-core-common-eventing-rxjs';

// TODO: Move to other place where we store interfaces
export interface ReviewItem {
  id: string;
}

// TODO: Move to other place where we store interfaces
export interface GetReviewsCountResponse {
  items: ReviewItem[];
}

@Injectable()
export class ReviewsService {
  private static readonly TYPE: string = 'ReviewsService';
  private readonly basePath: string = environment.apis.accounts;

  public constructor(
    @Inject(AUTH_TOKENS.IdentityService)
    private readonly identityService: IdentityService,
    @Inject(COMMON_TOKENS.LogService)
    private readonly logService: LogService,
    private readonly httpClient: HttpClient
  ) {}

  public getReviewsCount(
    params?: HttpParams
  ): Observable<GetReviewsCountResponse> {
    return from(this.getToken()).pipe(
      switchMap(token => {
        // TODO: move this logic to interceptor. Potentially could be done using AngularKeycloakHttpInterceptor
        const headers = new HttpHeaders().set(
          'Authorization',
          `Bearer ${token}`
        );

        return this.httpClient.get<GetReviewsCountResponse>(
          `${this.basePath}/reviews-minimal`,
          {
            params,
            headers,
          }
        );
      }),
      catchError(this.handleError.bind(this))
    );
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    this.logService.debug(
      ReviewsService.TYPE,
      `An error occurred: ${error.message}`
    );
    return throwError(() => new Error(error.message));
  }

  private async getIdentity(): Promise<Identity<KeycloakProfileData>> {
    return await this.identityService.identity
      .convertWith<Subject<Identity<KeycloakProfileData>>>(RxJsSubjectBridge)
      .pipe(
        filter(x => !!x),
        take(1)
      )
      .toPromise();
  }

  private async getToken(): Promise<string> {
    const identity = await this.getIdentity();

    return await identity.profile.keycloak.token.encoded
      .convertWith<Subject<string>>(RxJsSubjectBridge)
      .pipe(
        filter(x => !!x),
        take(1)
      )
      .toPromise();
  }
}
