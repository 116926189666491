export enum FeedbackStarter {
  Popup = 'POPUP',
  Sleeve = 'SLEEVE',
}

export interface FeedbackTemplate {
  id: string;
}

export interface FeedbackCustomer {
  email: string;
  firstName?: string;
  lastName?: string;
}

export interface FeedbackChannel {
  id: string;
}

export interface FeedbackTransaction {
  reference: string;
  date: string;
}

export interface FeedbackRouteMetadata {
  page: string;
  pluginName: string;
  pluginVersion: string;
}

export interface FeedbackMetadata extends FeedbackRouteMetadata {
  starter: FeedbackStarter;
  'customer-reference': string;
  'customer-reference-type': 'ETRUSTED_ACCOUNT_ID';
}

export interface FeedbackBody {
  languageTag: string;
  questionnaireLinkRequest: {
    type: 'ts-control-center-usage';
    questionnaireTemplate: FeedbackTemplate;
    customer: FeedbackCustomer;
    transaction: FeedbackTransaction;
    metadata: FeedbackMetadata;
  };
}

export interface FeedbackQuestionnaireResponse {
  id: string;
  link: string;
  questionnaireToken: string;
}

export interface KeycloakTokenPayload {
  email: string;
  given_name: string;
  family_name: string;
  'https://etrusted.com': { accountRef: string };
}
