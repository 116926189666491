import { NgModule } from '@angular/core';
import { TswpCarrierDefaultConfigModule } from '@trustedshops/tswp-carrier-core';
import { IdentityResolverDelegate, TOKENS } from '@trustedshops/tswp-core-authorization';
import {
  TOKENS as COMMON_TOKENS,
  CultureInfoDeAT,
  CultureInfoDeCH,
  CultureInfoDeDE,
  CultureInfoEnGB,
  CultureInfoEnUS,
  CultureInfoEsES,
  CultureInfoFrBE,
  CultureInfoFrCH,
  CultureInfoFrFR,
  CultureInfoItCH,
  CultureInfoItIT,
  CultureInfoNlBE,
  CultureInfoNlNL,
  CultureInfoPlPL,
  CultureInfoPtPT,
  TranslationFile
} from '@trustedshops/tswp-core-common';
import { PluginResolverServiceImpl } from '../services/plugin-resolver.service';
import { BreadcrumbsInitializer } from './breadcrumbs.bootstrapper';
import { culturesAvailable } from './cultures.configuration';
import { environment } from './get-environment.util';
import { OrganizationalContainerBootstrapper } from './organizational-container.bootstrapper';
import { PluginsInitializer } from './plugins.bootstrapper';
import { RoutingInitializer } from './routing.bootstrapper';
import { SystemCriticalBootstrapperInitializer } from './system-critical.bootstrapper';
@NgModule({
  imports: [
    TswpCarrierDefaultConfigModule.withConfig({
      plugins: {
        resolver: PluginResolverServiceImpl,
        storageRootPath: environment.storage.plugins.rootPath
      },
      globalization: {
        cultures: culturesAvailable,
        defaultCulture: CultureInfoEnGB,
        baseCultureMappings: [
          [CultureInfoEnGB, CultureInfoEnGB],
          [CultureInfoEnUS, CultureInfoEnGB],

          [CultureInfoItIT, CultureInfoItIT],
          [CultureInfoItCH, CultureInfoItIT],

          [CultureInfoDeDE, CultureInfoDeDE],
          [CultureInfoDeCH, CultureInfoDeDE],
          [CultureInfoDeAT, CultureInfoDeDE],

          [CultureInfoPlPL, CultureInfoPlPL],
          [CultureInfoPtPT, CultureInfoPtPT],
          [CultureInfoEsES, CultureInfoEsES],

          [CultureInfoFrFR, CultureInfoFrFR],
          [CultureInfoFrBE, CultureInfoFrFR],
          [CultureInfoFrCH, CultureInfoFrFR],

          [CultureInfoNlNL, CultureInfoNlNL],
          [CultureInfoNlBE, CultureInfoNlNL]
        ]
      },
      diagnostics: {
        logging: {
          logLevels: environment.diagnostics.logging.logLevels,
          filters: environment.diagnostics.logging.filters,
          sentry: environment.diagnostics.logging.sentry,
          instana: environment.diagnostics.logging.instana
        }
      },
      environment: {
        friendlyName: 'Trusted Shops B2B Platform',
        id: 'tswp-b2b',
        targetUserGroup: environment.targetGroup,
        stage: {
          name: environment.name,
          productionModeEnabled: environment.production
        }
      },
      authorization: {
        sessionProviderConfig: {
          url: environment.authorization.keycloakUrl,
          clientId: environment.authorization.clientId,
          realm: environment.authorization.realm,
          sessionVerificationType: environment.authorization.sessionVerificationType,
          silentCheckSsoRedirectUri: environment.authorization.silentCheckSsoRedirectUri,
          initializationOptions: {
            loadUserProfile: false,
            loadUserInfo: false
          }
        },
        protectedEndpoints: [
          environment.apis.plugins,
          environment.apis.featureBooking
        ]
      }
    })
  ],
  exports: [
    TswpCarrierDefaultConfigModule
  ],
  providers: [
    BreadcrumbsInitializer,
    OrganizationalContainerBootstrapper,
    {
      provide: COMMON_TOKENS.DefaultTranslationServiceConfig,
      useValue: {
        translationKeyResolver: (translations: TranslationFile, key: string) => translations[key],
        localeFileNameResolver: (locale: string) => `assets/i18n/${locale}.json`
      }
    },
    RoutingInitializer,
    { provide: TOKENS.IdentityResolver, useValue: ((_, __, ___, token) => token.identityRef) as IdentityResolverDelegate },
    PluginsInitializer,
    SystemCriticalBootstrapperInitializer
  ]
})
export class TrustedShopsWebPlatformCoreModule {
}
