import {
  CultureInfoDeDE,
  CultureInfoDeAT,
  CultureInfoDeCH,
  CultureInfoEnGB,
  CultureInfoItIT,
  CultureInfoItCH,
  CultureInfoFrFR,
  CultureInfoFrBE,
  CultureInfoFrCH,
  CultureInfoPlPL,
  CultureInfoPtPT,
  CultureInfoEsES,
  CultureInfoNlNL,
  CultureInfoNlBE
} from '@trustedshops/tswp-core-common';

export const culturesAvailable = [
  // German
  CultureInfoDeDE,
  CultureInfoDeAT,
  CultureInfoDeCH,

  // English
  CultureInfoEnGB,

  // Italian
  CultureInfoItIT,
  CultureInfoItCH,

  // French
  CultureInfoFrFR,
  CultureInfoFrBE,
  CultureInfoFrCH,

  // Polish
  CultureInfoPlPL,

  // Portuguese
  CultureInfoPtPT,

  // Spanish
  CultureInfoEsES,

  // Dutch
  CultureInfoNlNL,
  CultureInfoNlBE
];
