import { Inject, Injectable } from '@angular/core';
import {
  Identity,
  IdentityService,
  TOKENS,
} from '@trustedshops/tswp-core-authorization';
import { from, of, Subject } from 'rxjs';
import {
  first,
  filter,
  switchMap,
  map,
  catchError,
  take,
  distinctUntilChanged,
} from 'rxjs/operators';
import { KeycloakProfileData } from '@trustedshops/tswp-core-authorization-keycloak';
import { RxJsSubjectBridge } from '@trustedshops/tswp-core-common-eventing-rxjs';
import {
  TOKENS as COMMON_TOKENS,
  LogService,
  AbstractPersistentEvent,
  CultureInfoService,
  CultureInfo,
} from '@trustedshops/tswp-core-common';
import {
  TOKENS as MASTERDATA_TOKENS,
  FeatureBookingService,
} from '@trustedshops/tswp-core-masterdata';
import userFlow from 'userflow.js';
import { environment } from '../configuration/get-environment.util';
import { ReviewsService } from './reviews.service';
import { UserService } from './user.service';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';

// TODO: Move to other place where we store interfaces
interface UserFlowIdentity {
  identityRef: string;
  email: string;
  firstName: string;
  lastName: string;
  hasReviews: boolean;
  createdAt: string;
  changedAt: string;
  isFreeAccount: boolean;
  metadata: any[];
  locale_code: string;
}

@Injectable()
export class UserFlowService {
  private static readonly TYPE: string = 'UserFlowService';

  public constructor(
    @Inject(TOKENS.IdentityService)
    private readonly identityService: IdentityService,
    @Inject(COMMON_TOKENS.LogService) private readonly logService: LogService,
    @Inject(MASTERDATA_TOKENS.FeatureBookingService)
    private readonly featureBookingService: FeatureBookingService,
    @Inject(COMMON_TOKENS.CultureInfoService)
    private readonly cultureInfoService: CultureInfoService,
    private readonly reviewsService: ReviewsService,
    private readonly userService: UserService,
  ) {}

  /*
   This method is called once the application has been loaded.
   It inits the UserFlow library and update User profile in the UserFlow
   */
  public init(): void {
    const getIdentityProfile = this.identityService.identity
      .convertWith<Subject<Identity<KeycloakProfileData>>>(RxJsSubjectBridge)
      .pipe(
        filter((identity: Identity<KeycloakProfileData>) => !!identity),
        first(),
        map(
          (identity: Identity<KeycloakProfileData>) =>
            identity.profile.keycloak.token
              .payload as AbstractPersistentEvent<any>,
        ),
        // Ensure that this subscription evaluates once
        take(1),
      );

    // TODO: Move inline method outside of the init method
    /* Get User logged in data */
    const getKeycloakProfile = (payload: AbstractPersistentEvent<any>) =>
      payload.convertWith<Subject<any>>(RxJsSubjectBridge);

    /* Get Locale Code */
    const getLocaleCode = () =>
      this.cultureInfoService.currentCulture
        .convertWith<Observable<CultureInfo>>(RxJsSubjectBridge)
        .pipe(
          map((cultureInfo) => cultureInfo.ietfLanguageTag),
          distinctUntilChanged(),
        );

    /* Get Account reviews count */
    const getAccountReviews = () => {
      let params = new HttpParams();
      params = params.set('count', '1');

      return this.reviewsService.getReviewsCount(params);
    };

    /* Check if Account is still in the Free state */
    const getIsFreeAccount = () =>
      from(this.featureBookingService.hasAccountFeatureBooked('FREE_ACCOUNT'));

    /* Get logged in User Details to extract createdAt and updatedAt dates */
    const getUserDetails = (profile: any) => {
      const accountRef = profile['https://etrusted.com'].accountRef;
      const identityRef = profile.identityRef;

      return this.userService.getUser(identityRef, accountRef);
    };

    /* Define UserFlowIdentity object to send it to the UserFlow */
    const mapToUserFlowIdentity = ({
      hasReviews,
      profile,
      user,
      isFreeAccount,
      locale_code,
    }: any): UserFlowIdentity => ({
      identityRef: profile.identityRef,
      email: profile.email,
      firstName: profile.given_name,
      lastName: profile.family_name,
      hasReviews,
      createdAt: user.createdAt,
      changedAt: user.changedAt,
      isFreeAccount,
      metadata: [],
      locale_code,
    });

    /* Init UseFlow library */
    const initializeUserFlow = (user: UserFlowIdentity) => {
      userFlow.init(environment.userFlow.apiKey);

      this.logService.debug(
        UserFlowService.TYPE,
        'UserFlowService initialized',
        user,
      );

      return from(userFlow.identify(user.identityRef, { ...user }));
    };

    getIdentityProfile
      .pipe(
        switchMap(getKeycloakProfile),
        // TODO: Replace the following switchMap methods with forkJoin.
        switchMap((profile) =>
          getAccountReviews().pipe(
            map((response) => ({
              hasReviews: response?.items?.length > 0,
              profile,
            })),
            catchError((err) => {
              this.logService.debug(
                UserFlowService.TYPE,
                `Get Reviews failed: ${err}`,
              );

              return of({ hasReviews: undefined, profile });
            }),
          ),
        ),
        switchMap(({ hasReviews, profile }) =>
          getUserDetails(profile).pipe(
            map((user) => ({
              hasReviews,
              profile,
              user,
            })),
            catchError((err) => {
              this.logService.debug(
                UserFlowService.TYPE,
                `Get User Details failed: ${err}`,
              );

              return of({ hasReviews, profile, user: {} });
            }),
          ),
        ),
        switchMap(({ hasReviews, profile, user }) =>
          getIsFreeAccount().pipe(
            map((isFreeAccount) => ({
              hasReviews,
              profile,
              user,
              isFreeAccount,
            })),
            catchError((err) => {
              this.logService.debug(
                UserFlowService.TYPE,
                `Check if Free Account failed: ${err}`,
              );

              return of({
                hasReviews,
                profile,
                user,
                isFreeAccount: undefined,
              });
            }),
          ),
        ),
        switchMap(({ hasReviews, profile, user, isFreeAccount }) =>
          getLocaleCode().pipe(
            map((locale_code) => ({
              hasReviews,
              profile,
              user,
              isFreeAccount,
              locale_code,
            })),
            catchError((err) => {
              this.logService.debug(
                UserFlowService.TYPE,
                `Get locale_code failed: ${err}`,
              );

              return of({
                hasReviews,
                profile,
                user,
                isFreeAccount,
                locale_code: undefined,
              });
            }),
          ),
        ),
        map(mapToUserFlowIdentity),
        switchMap(initializeUserFlow),
        catchError(this.handleError.bind(this)),
      )
      .subscribe();
  }

  private handleError(error: any): Observable<any> {
    this.logService.debug(
      UserFlowService.TYPE,
      `UserFlowService init failed: ${error}`,
    );
    return of(error);
  }
}
