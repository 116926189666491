import {
  TOKENS,
  PageTitleCompositionService,
  BreadcrumbsCompositionService,
} from '@trustedshops/tswp-core-ui';
import { Injectable, APP_INITIALIZER, Inject } from '@angular/core';
import { BootstrapperBase, EventSubscription } from '@trustedshops/tswp-core-common';
import { Title } from '@angular/platform-browser';
import { DefaultPageTitleProvider } from '@trustedshops/tswp-carrier-core';
import { ENVIRONMENT, Environment } from '../models/environment.type';

@Injectable()
export class PageTitleBootstrapper implements BootstrapperBase {
  private _lastSubscription?: EventSubscription<string>;

  //#region Ctor
  public constructor(
    private readonly _titleService: Title,

    @Inject(TOKENS.PageTitleCompositionService)
    private readonly _pageTitleCompositionService: PageTitleCompositionService,

    @Inject(TOKENS.BreadcrumbsCompositionService)
    private readonly _breadcrumbsCompositionService: BreadcrumbsCompositionService,

    @Inject(ENVIRONMENT)
    private readonly _environment: Environment
  ) {}
  //#endregion

  //#region Public Methods
  public async initialize(): Promise<void> {
    this._pageTitleCompositionService.configureDefaultPageTitleProvider(
      new DefaultPageTitleProvider(
        this._breadcrumbsCompositionService,
        this._environment.pageTitle
      )
    );

    this._pageTitleCompositionService.currentPageTitle.subscribe((x) => {
      if (!!this._lastSubscription) {
        this._lastSubscription.unsubscribe();
        this._lastSubscription = undefined;
      }

      this._lastSubscription = x.subscribe(async (pageTitle) => {
        this._titleService.setTitle(pageTitle);
      });
    });
  }
  //#endregion
}

export const PageTitleBootstrapperInitializer = [
  PageTitleBootstrapper,
  {
    provide: APP_INITIALIZER,
    multi: true,
    useFactory: (initializer: PageTitleBootstrapper) => {
      return () => initializer.initialize();
    },
    deps: [PageTitleBootstrapper],
  },
];
