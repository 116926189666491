import { DOCUMENT } from '@angular/common';
import { APP_INITIALIZER,  Inject, Injectable, Injector } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { BootstrapperBase } from '@trustedshops/tswp-core-common';
import { TertiaryMenuBarComponent } from './tertiary-menu-bar.component';

@Injectable()
export class TertiaryMenuBarComponentBootstrapper implements BootstrapperBase {
  //#region Ctor
  public constructor(
    private readonly _injector: Injector,
    @Inject(DOCUMENT) private readonly _document: Document) {
  }
  //#endregion

  //#region Public Methods
  public async initialize(): Promise<void> {
    const customElement = createCustomElement(TertiaryMenuBarComponent, {
      injector: this._injector
    });

    this._document.defaultView.customElements
      .define(
        TertiaryMenuBarComponent.selector,
        customElement);
  }

  //#endregion
}

export const TertiaryMenuBarComponentInitializer = [
  TertiaryMenuBarComponentBootstrapper,
  {
    provide: APP_INITIALIZER,
    multi: true,
    useFactory: (initializer: TertiaryMenuBarComponentBootstrapper) => {
      return () => initializer.initialize();
    },
    deps: [TertiaryMenuBarComponentBootstrapper]
  }];
